:root {
  --font-size: 1rem;

  --line-height: 1.4em;
  --line-width: 1ch;
}

.container {
  margin: 0;
}

.container.border {
  border-style: double;
  --border-width-vertical: 0.6em;
  border-width: var(--border-width-vertical) 0.6ch;
  margin: calc(var(--line-height, 1.4em) / 2 - 0.3em) 0.2ch;
  padding: calc(var(--line-height, 1.4em) / 2 - 0.3em) 1.2ch;
}

.container.window {
  align-self: center;
}

* {
  padding: 0;
  margin: 0;
  font-size: var(--font-size);
  font-family: monospace;
}

body {
  /* prevent scrollbars */
  margin: 0;
}

nav > * {
  padding: 0 2ch;
}

nav > ul > li > * {
  padding: 0 1ch;
}

main {
  padding: var(--line-height) 1ch;
  row-gap: var(--line-height);
}

header {
  text-align: center;
}

a.container {
  text-decoration: none;
}

li,
ul {
  list-style-type: none;
}

.loading::before {
  content: "";
  animation: anim1 6000ms steps(1, end) infinite;
}

.id {
  display: block;
  width: 9ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.border {
  border-width: 0.6em 0.6ch;
  border-style: double;
  margin: calc(var(--line-height, 1.4em) / 2 - 0.3em) 0.2ch;
  padding: calc(var(--line-height, 1.4em) / 2 - 0.3em) 0.2ch;
}

/* 
 * Button
 */

.button {
  margin-right: 1ch;
  margin-left: 1ch;
  margin-bottom: var(--line-height);

  padding: 0 1ch;
  outline: 0;
  border: none;
  border-radius: 0;
  cursor: pointer;

  text-align: center;

  flex-grow: 1;
}

.button.disabled {
  text-decoration-line: line-through;
  cursor: not-allowed;
}

@keyframes anim1 {
  0% {
    content: "[<---]";
  }
  3% {
    content: "[|---]";
  }
  6% {
    content: "[>---]";
  }
  9% {
    content: "[=---]";
  }
  12% {
    content: "[=<--]";
  }
  15% {
    content: "[=|--]";
  }
  18% {
    content: "[=>--]";
  }
  21% {
    content: "[==--]";
  }
  24% {
    content: "[==<-]";
  }
  27% {
    content: "[==|-]";
  }
  30% {
    content: "[==>-]";
  }
  33% {
    content: "[===-]";
  }
  36% {
    content: "[===<]";
  }
  39% {
    content: "[===|]";
  }
  42% {
    content: "[===>]";
  }
  45% {
    content: "[====]";
  }

  50% {
    content: "[<===]";
  }
  53% {
    content: "[|===]";
  }
  56% {
    content: "[>===]";
  }
  59% {
    content: "[-===]";
  }
  62% {
    content: "[-<==]";
  }
  65% {
    content: "[-|==]";
  }
  68% {
    content: "[->==]";
  }
  71% {
    content: "[--==]";
  }
  74% {
    content: "[--<=]";
  }
  77% {
    content: "[--|=]";
  }
  80% {
    content: "[-->=]";
  }
  83% {
    content: "[---=]";
  }
  86% {
    content: "[---<]";
  }
  89% {
    content: "[---|]";
  }
  92% {
    content: "[--->]";
  }
  95% {
    content: "[----]";
  }
}
