:root {
  /*
    * https://developer.mozilla.org/en-US/docs/Web/CSS/named-color#standard_colors
  */

  --success-color: green;
  --success-color-secondary: darkgreen;
  --success-color-highlight: lime;
  --success-color-invert: black;

  --danger-color: maroon;
  --danger-color-secondary: darkred;
  --danger-color-highlight: red;
  --danger-color-invert: white;

  --warning-color: orange;
  --danger-color-secondary: olive;
  --warning-color-highlight: yellow;
  --warning-color-invert: black;

  /*
   * Input
   */

  --input-background-color: gray;
  --input-error-background-color: maroon;
  --input-color: white;
  --input-placeholder-color: silver;

  --input-background-active-color: yellow;
  --input-error-background-active-color: red;
  --input-active-color: black;
  --input-placeholder-active-color: silver;
}

body {
  background-color: darkcyan;
  color: black;
  --background-color-highlight: cyan;
}

nav {
  background-color: silver;

  --background-color-highlight: gray;
  --background-color-secondary: black;
  --color: black;
  --color-secondary: white;
}


main {
  background-color: black;
  color: white;
}





/*
 * links
 */


a.disabled {
  cursor: not-allowed;
}

a:not(.disabled):hover {
  --background-color: var(--background-color-highlight);
  --color: var(--color-highlight);
}

a:visited, a:link, a {
  color: var(--color);
}

a:target, a.active {
  --background-color: var(--background-color-secondary);
  --color: var(--color-secondary);
}

a {
  background-color: var(--background-color);
  color: var(--color);
}






.container.border {
  border-color: var(--color);
}

.alert {
  --background-color: var(--danger-color);
  --background-color-secondary: var(--danger-color-secondary);
  --background-color-highlight: var(--danger-color-highlight);
  --color: var(--danger-color-invert);

  color: var(--background-color);
}

.success {
  --background-color: var(--success-color);
  --background-color-secondary: var(--success-color-secondary);
  --background-color-highlight: var(--success-color-highlight);
  --color: var(--success-color-invert);

  color: var(--background-color);
}

.window {
  --background-color: gray;
  --background-color-secondary: darkgray;
  --background-color-highlight: silver;
  --color: black;
  --color-secondary: black;
  --color-highlight: black;
}

.container {
  background-color: var(--background-color);
  color: var(--color);
}

.list-item:nth-of-type(even) {
  --background-color: var(--background-color-secondary);
  --color: var(--color-secondary);
}


.id {
  color: var(--secondary-color);
}

.warning {
  color: var(--error-color);
}

/*
 * try making text accessable against background color
 * https://css-tricks.com/css-variables-calc-rgb-enforcing-high-contrast-colors/
 */

.button:not(.disabled):hover {
  background-color: var(--background-color-highlight);
}

.button {
  box-shadow: 0.7ch 0.7em var(--background-color-secondary);
  background-color: var(--background-color);
  color: var(--color);
}

.button:active {
  box-shadow: 0.2ch 0.2em var(--background-color-secondary);
}

.button.primary {
  --background-color: var(--success-color);
  --color: var(--success-color-invert);
  --background-color-highlight: var(--success-color-highlight);
}

.button.secondary {
  --background-color: navy;
  --color: white;
  --background-color-highlight: blue;
}

.button.danger {
  --background-color: var(--danger-color);
  --color: var(--danger-color-invert);
  --background-color-highlight: var(--danger-color-highlight);
}

.button.warning {
  --background-color: var(--warning-color);
  --color: var(--warning-color-invert);
  --background-color-highlight: var(--warning-color-highlight);
}
