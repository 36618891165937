.buttonGroup {
  margin-top: var(--line-height, 1.7em);
}

/* .fieldset > legend.legend {
  margin-top: calc(var(--line-height, 1.7em) / -2);
} */

legend.legend {
  padding-left: 1ch;
  padding-right: 1ch;
}

.fieldset {
  display: flex;
  flex-direction: column;
  row-gap: var(--line-height, 1.7em);
  padding: calc(var(--line-height, 1.7em) / 2 - 3px) calc(1ch + 0.6ch - 3px);
  margin: calc(var(--line-height, 1.7em) / 2) 0.4ch;
  border-style: double;
  border-width: 3px;
}
