.container {
  composes: global(container);
}

.container.row {
  flex-direction: row;
}

.spaced.container {
  row-gap: var(--line-height, 1.7em);
  column-gap: 1ch;
}

.padded-horizontal.container {
  padding-left: 1ch;
  padding-right: 1ch;
}

.padded-vertical.container {
  padding-top: var(--line-height);
  padding-bottom: var(--line-height);
}

.margined-horizontal.container {
  margin-left: 1ch;
  margin-right: 1ch;
}

.margined-vertical.container {
  margin-top: var(--line-height);
  margin-bottom: var(--line-height);
}

.window {
  composes: global(window);
}

.border {
  composes: global(border);
}

fieldset.container.border > legend {
  margin-top: calc(
    (var(--line-height, 1.7em) - (1em - var(--border-width-vertical) / 2)) / -2
  );
}

.border.border-single {
  border-style: solid;
  border-width: 0.1em 0.1ch;
  margin: calc(var(--line-height, 1.7em) / 2) 0.5ch;
  padding: calc(var(--line-height, 1.7em) / 2 - 0.1em) 1.4ch;
}
