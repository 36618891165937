.field {
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
}

.label {
  /* white-space: nowrap; */
  flex: 1;
  /* flex-shrink: 1;
  flex-grow: 1; */
}

.inputContainer {
  /* width: 100%; */
  flex: 1;
  /* flex: 2;
  flex-shrink: 2;
  flex-grow: 2;
  display: flex; */
  display: flex;
  white-space: nowrap;
}

.input {
  width: 100%;
  /* flex: 1; */
}

.input[type="checkbox"] {
  width: auto;
}

.error {
  color: var(--error-color, red);
  white-space: normal;
}
