/* .id {
    width: 9ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--secondary-color);
} */

/* .id::before {
    content: "[";
}

.id::after {
  content: "]";
} */

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 1ch;
}

.id {
  /* display: block;
  width: 23ch; */
  white-space: nowrap;
  overflow: hidden;
  color: darkslategray;
  text-overflow: ellipsis;
}

.idContainer {
  /* width: 25ch; */
  /* overflow: hidden; */
  flex: 1;
}

.detailsContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  column-gap: 1ch;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.detailsContainer > * {
  overflow: hidden;
}
