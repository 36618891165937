.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

#root {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "main"
    "footer";
}

nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

nav > ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

nav a {
  display: block;
}


main {
  grid-area: main;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

main > .container,
main > section,
main > * {
  width: 100%;
}

@media (min-width: 576px) {
  main > .container,
  main > section,
  main > section {
    max-width: 510px;
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  main > .container,
  main > section,
  main > section {
    max-width: 700px;
  }
}
@media (min-width: 992px) {
  main > .container,
  main > section,
  main > section {
    max-width: 920px;
  }
}
@media (min-width: 1200px) {
  main > .container,
  main > section,
  main > section {
    max-width: 1130px;
  }
}