.textarea {
  outline: 0;
  border: none;
  border-radius: 0;
}

/*
 * Colours
 */

.textarea {
  background-color: white;
  color: black;
}

.textarea:invalid {
  background-color: red;
}

.textarea:focus {
  background-color: yellow;
}

.textarea:focus:invalid {
  background-color: orange;
}

.textarea:read-only {
  background-color: lightgray;
}

.textarea:disabled {
  background-color: darkgray;
  text-decoration: line-through;
}
