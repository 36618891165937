.input {
  outline: 0;
  border: none;
  border-radius: 0;
  min-width: 36ch;
  /* min-width: 33ch; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.input[type="checkbox"] {
  appearance: none;
  cursor: pointer;
}

.input[type="checkbox"]::after {
  content: "[ ]";
}

.input[type="checkbox"]:checked::after {
  content: "[X]";
}

input[type="file"]::file-selector-button {
  display: none;
}

/*
 * Colours
 */

.input {
  background-color: white;
  color: black;
}

.input:invalid {
  background-color: red;
}

.input:focus {
  background-color: yellow;
}

.input:focus:invalid {
  background-color: orange;
}

.input:read-only {
  background-color: lightgray;
  /*
  this prevents the user from selecting any text for some reason
  user-select: all;
  */
}

.input:disabled {
  background-color: darkgray;
  text-decoration: line-through;
}
